<template>
  <div class="row">
    <div class="col-md-6 offset-3">
      <div class="card form-body">
        <div class="card-body">
          <nav
            class="nav nav-pills nav-justified"
            v-if="currentUser.u.is_admin == 1"
          >
            <a
              class="nav-link"
              @click="active_tab = 'deporder-trp'"
              :class="active_tab == 'deporder-trp' ? 'active' : ''"
              aria-current="page"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'deporder-trp' ? 'text-white' : ''"
              ></i>
              Deporder (TRP)</a
            >
            <a
              class="nav-link"
              @click="active_tab = 'deporder'"
              :class="active_tab == 'deporder' ? 'active' : ''"
              aria-current="page"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'deporder' ? 'text-white' : ''"
              ></i>
              Deporder</a
            >
            <a
              class="nav-link"
              @click="active_tab = 'fdle'"
              :class="active_tab == 'fdle' ? 'active' : ''"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'fdle' ? 'text-white' : ''"
              ></i>
              Fdle</a
            >
            <a
              class="nav-link"
              @click="active_tab = 'acha'"
              :class="active_tab == 'acha' ? 'active' : ''"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'acha' ? 'text-white' : ''"
              ></i>
              AHCA</a
            >
            <a
              class="nav-link"
              @click="active_tab = 'apostille'"
              :class="active_tab == 'apostille' ? 'active' : ''"
              href="#"
            >
              <i
                class="fa fa-comment mr-2"
                :class="active_tab == 'apostille' ? 'text-white' : ''"
              ></i>
              Apostille</a
            >
          </nav>
          <hr v-if="currentUser.u.is_admin == 1" />

          <v-deporder v-if="active_tab == 'deporder-trp'" key="2"></v-deporder>
          <v-deporder v-if="active_tab == 'deporder'" :trp="false" key="1"></v-deporder>
          <v-fdle v-if="active_tab == 'fdle'"></v-fdle>
          <v-acha v-if="active_tab == 'acha'"></v-acha>
          <v-apostille v-if="active_tab == 'apostille'"></v-apostille>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
import vDeporder from "@/pages/backend/settings/server_config/deporder";
import vFdle from "@/pages/backend/settings/server_config/fdle";
import vAcha from "@/pages/backend/settings/server_config/acha";
import vApostille from "@/pages/backend/settings/server_config/apostille";
export default {
  components: {
    vDeporder,
    vFdle,
    vAcha,
    vApostille
  },
  data() {
    return {
      active_tab: "deporder-trp",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Setting",
          route: ""
        },
        {
          id: 2,
          title: "Submission Config",
          route: ""
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  }
};
</script>
