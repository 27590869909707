<template>
  <form class="form" @submit.stop.prevent="onSubmit">
    <div class="form-group">
      <label for="">Email <span class="text-danger">*</span></label>
      <input
        type="email"
        name=""
        class="form-control"
        id=""
        v-model="email"
        :class="errors['email'] ? 'border border-danger' : ''"
      />
      <span v-if="errors['email']" class="text-danger">{{
        errors["email"][0]
      }}</span>
    </div>

    <hr />
    <!-- 
      <h4 @click="checkTwilioKey()">Checkme</h4> -->
    <button
      class="btn btn-success"
      ref="kt_apostille_config_submit"
      style="float:right"
    >
      Submit
    </button>
  </form>
</template>

<script>
import {
    FETCH_APOSTILLE_CONFIG,
    SUBMIT_APOSTILLE_CONFIG
} from "@/core/services/store/actions.type";
export default {
  data() {
    return {
      email: "",
      lsid: "",
      check_url: "",
      errors: [],
      id: 0
    };
  },
  created() {
    this.fetchData();
  },

  methods: {
    // checkTwilioKey() {
    //   const client = require("twilio")(this.account_sid, this.auth_token);
    //   client.newKeys.create({friendlyName: 'User Joey'})
    //           .then(new_key => console.log(new_key.sid));
    // },
    fetchData(){
        this.$store.dispatch(FETCH_APOSTILLE_CONFIG).then(data => {
        var data_ = data.data;
        this.email = data_.email ? data_.email : "";
        this.id = data_.id ? data_.id : 0;
        });
    },
    onSubmit() {
      this.errors = [];
      const submitButton = this.$refs["kt_apostille_config_submit"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      var data = {
        email: this.email,
        id: this.id,
      };

      this.$store
        .dispatch(SUBMIT_APOSTILLE_CONFIG, data)
        .then(data => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(data.msg);
          this.fetchData();
        })
        .catch(err => {
          this.errors = err.msg;
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e(err.msg);
        });
    }
  }
};
</script>
